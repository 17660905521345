import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

const NotFoundPage = () => (
  <Fragment>
    <Helmet>
      <title>
        Khufu - Quotes Generator - 404
      </title>
    </Helmet>
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Fragment>
)

export default NotFoundPage
